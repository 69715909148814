import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import awsLogo from "@/assets/img/aws_logo.svg";
import azureLogo from "@/assets/img/azurelogo.svg";
import style from "@/page-components/cost-Recommendation/cost-Recommendation.module.css";

const VBarChart = ({projectedData}) => {
  const [hoveredStack, setHoveredStack] = useState(null);

  const transformData = (data) => {
    return (data || []).map((entry, index) => ({
      name: entry.aws.month,
      awsTotalCost: entry.aws.total_cost,
      awsProjectedSavings: entry.aws.projected_savings,
      awsActualSavings: entry.aws.actual_savings,
      awsPercentage: entry.aws.percentage,
      awsTotalRecommendation: entry.aws.total_recommendations,
      awsTotalRecommendationApplied: entry.aws.recommendations_applied,
      azureTotalCost: entry.azure.total_cost,
      azureProjectedSavings: entry.azure.projected_savings,
      azureActualSavings: entry.azure.actual_savings,
      azurePercentage: entry.azure.percentage,
      azureTotalRecommendation: entry.azure.total_recommendations,
      azureTotalRecommendationApplied: entry.azure.recommendations_applied,
    }));
  };

  const transformedData = transformData(projectedData);
  const CustomLegend = () => (
    <div style={{ display: "flex", justifyContent: "center", gap: "30px", marginBottom: "10px" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <span style={{fontSize:"12px",fontWeight:"500",color:"#000000"}}>AWS:</span>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: "#FFE1B3",
              borderRadius: "2px",
            }}
          ></div>
          <span style={{fontSize:"12px",fontWeight:"400",color:"#000000"}}>Projected Savings</span>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: "#FF9900",
              borderRadius: "2px",
            }}
          ></div>
          <span style={{fontSize:"12px",fontWeight:"400",color:"#000000"}}> Actual Savings</span>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <span style={{fontSize:"12px",fontWeight:"500",color:"#000000"}}>AZURE:</span>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: "#BCD7E9",
              borderRadius: "2px",
            }}
          ></div>
          <span style={{fontSize:"12px",fontWeight:"400",color:"#000000"}}>Projected Savings</span>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: "#1F78B4",
              borderRadius: "2px",
            }}
          ></div>
          <span style={{fontSize:"12px",fontWeight:"400",color:"#000000"}}>Actual Savings</span>
        </div>
      </div>
    </div>
  );
  
  const CustomTooltip = ({ active, payload, label, stackId }) => {
    if (active && payload && payload.length) {
      const filteredPayload = payload.filter((entry) =>
        entry.dataKey.startsWith(stackId === "a" ? "aws" : "azure")
      );
      const isAWS = filteredPayload[0]?.dataKey.startsWith("aws");
      const cloudData = isAWS ? transformedData[0] : transformedData[0];

      console.log("cloudData.azureTotalRecommendation",cloudData.azureTotalRecommendation)
  
      return (
        <div className="custom-tooltip" style={{width:"260px",paddingBottom:"10px",display:"flex",gap:"5px"}}>
          <div className="tooltip_date">
            <div className="tooltip_date_css">{label}</div>
          </div>
          <table>
            <tbody style={{paddingLeft:"12px",paddingRight:"12px", display:"block"}}>
              {filteredPayload.length > 0 && (
                <tr >
                  <td
                    colSpan="2"
                    className="cloud-logo-cell"
                    style={{paddingLeft:"0px"}}
                  >
                    {isAWS ? (
                      <img style={{width:"17px",height:"10px"}} src={awsLogo} alt="AWS" className="cloud-logo" />
                    ) : (
                      <img style={{width:"17px",height:"10px"}} src={azureLogo} alt="Azure" className="cloud-logo" />
                    )}
                    <span>{isAWS ? "AWS" : "Azure"}</span>
                  </td>
                </tr>
              )}
              <tr className={style.tooltiptr}>
                <td className={style.tooltipkey}>Total Costs</td>
                <td className={style.tooltipvalue}>
                  ${isAWS ? cloudData.awsTotalCost.toFixed(2) : cloudData.azureTotalCost.toFixed(2)}
                </td>
              </tr>
              <tr className={style.tooltiptr}>
                <td className={style.tooltipkey}>Recommendations Applied</td>
                <td className={style.tooltipvalue}>
                  {isAWS
                    ? `${cloudData.awsTotalRecommendation}/${cloudData.awsTotalRecommendationApplied}`
                    : `${cloudData.azureTotalRecommendation}/${cloudData.azureTotalRecommendationApplied}`}
                </td>
              </tr>
              <tr className={style.tooltiptr}>
                <td className={style.tooltipkey}>Projected Savings</td>
                <td className={style.tooltipvalue}>
                  ${isAWS ? cloudData.awsProjectedSavings.toFixed(2) : cloudData.azureProjectedSavings.toFixed(2)}
                </td>
              </tr>
              <tr className={style.tooltiptr}>
                <td className={style.tooltipkey}>Actual Savings</td>
                <td className={style.tooltipvalue}>
                  <div style={{display:"flex",flexDirection:"column",gap:"6px"}}>
                    <span style={{textAlign:"end"}}>
                    ${isAWS ? cloudData.awsActualSavings.toFixed(2) : cloudData.azureActualSavings.toFixed(2)}
                    </span>
                    <span style={{color:"#3DA64F",fontSize:"11px",fontWeight:"500"}}>
                    {isAWS ? cloudData.awsPercentage.toFixed(2) : cloudData.azurePercentage.toFixed(2)}%
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };
  
  return (
    <>
    
    <ResponsiveContainer height={310} width="100%">
      <BarChart
        width={500}
        height={300}
        data={transformedData}
        margin={{
          // top: 20,
          right: 0,
          left: -15,
        }}
      >
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontFamily="Inter"
          fontWeight={400}
          stroke="#A3A3A3"
        />
        <YAxis
          label={{ fill: "#A3A3A3" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontFamily="Inter"
          fontWeight={400}
          stroke="#A3A3A3"
        />
        {/* <Legend
          align="center"
          verticalAlign="bottom"
          layout="horizontal"
          iconSize={12}
          iconType="Square"
          fontSize={12}
          fontWeight={400}
          fontFamily="Inter"
          fontStyle="normal"
        /> */}

        <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
        <Tooltip
          content={<CustomTooltip stackId={hoveredStack} />}
          cursor={{ fill: "transparent" }}
          // wrapperStyle={{ pointerEvents: "none" }}
        />

        <Bar
          barSize={130}
          dataKey="awsProjectedSavings"
          fill="#FFE1B3"
          stackId="a"
          name="Projected Saving "
          onMouseEnter={() => setHoveredStack("a")}
        />
        <Bar
          barSize={130}
          dataKey="awsActualSavings"
          fill="#FF9900"
          stackId="a"
          name="Actual Saving"
          onMouseEnter={() => setHoveredStack("a")}
        />
        <Bar
          barSize={130}
          dataKey="azureProjectedSavings"
          fill="#BCD7E9"
          stackId="b"
          name="Projected Saving "
          onMouseEnter={() => setHoveredStack("b")}
        />
        <Bar
          barSize={130}
          dataKey="azureActualSavings"
          fill="#1F78B4"
          stackId="b"
          name="Actual Saving"
          onMouseEnter={() => setHoveredStack("b")}
        />
      </BarChart>
    <CustomLegend />

    </ResponsiveContainer>
    </>
  );
};

export default VBarChart;
