import React, { useContext, useState, useMemo, useEffect } from "react";
import { FilterContext } from "../../components/filter-section/filter-section.component";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAllTableStatistics } from "@/services/costExplorer/getTableData.repo";
import TableErrorState from "@/components/States/TableErrorState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import Pagination from "@/components/pagination/pagination.component";
import clsx from "clsx";

const ServiceTable = ({
  dimension,
  filters,
  tags,
  service,
  toDate,
  fromDate,
  setShowData,
  tagname,
  dimensionData,
}) => {
  const filterContext = useContext(FilterContext);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleUsageDetails = (e, services) => {
    const dimensionCheck = (dimensionData?.dimensions || []).find(
      (d) => d.id === filterContext.selectedDimension
    );
    const dimensionUsageName = dimensionCheck?.name || "--";
    const localdata = {
      service: services,
      toDate: toDate,
      fromDate: fromDate,
      dimension: dimension,
      dimensionUsageName,
      tags: tags,
      filter: filters,
      cloud: service,
      tagname: tagname,
    };
    localStorage.setItem("usageDetails", JSON.stringify(localdata));
    navigate("/aws/details");
    window.scrollTo(0, 0);
  };

  const tableQuery = useMemo(() => {
    let tags = (filterContext?.selectedTags || []).filter((e) => {
      return e.filterOn.length > 0 && e.values.length > 0;
    });
    if (filterContext?.untagged) {
      tags = [];
    }
    if (filterContext?.untaggable) {
      tags = [];
    }
    const filters = (filterContext?.selectedFilters || []).filter((e) => {
      return e.dimension.length > 0 && e.values.length > 0;
    });
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;

    return {
      fromDate,
      toDate,
      serviceName: "filter",
      service: "aws",
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 0,
      dimension: filterContext?.selectedDimension,
      granularity: filterContext?.selectedGranularity,
      showUntaggedOnly: filterContext?.untagged ? true : undefined,
      showUntaggableOnly: filterContext?.untaggable ? true : undefined,
      filters: filters?.length > 0 ? filters : undefined,
      tags: tags?.length > 0 ? tags : undefined,
      tagname: filterContext?.selectedGroupTags,
    };
  }, [
    filterContext?.selectedTags,
    filterContext?.untagged,
    filterContext?.untaggable,
    filterContext?.selectedFilters,
    filterContext?.selectedDimension,
    filterContext?.selectedGranularity,
    filterContext?.selectedGroupTags,
    page,
    itemsPerPage,
    fromDate,
    toDate,
  ]);

  const {
    data,
    isLoading: tableLoading,
    error,
  } = useAllTableStatistics(tableQuery);

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("page", "1");
      return updatedParams;
    });
  }, [
    filterContext.selectedTags,
    filterContext.untagged,
    filterContext.untaggable,
    filterContext.selectedFilters,
    filterContext.selectedDimension,
    filterContext.selectedGranularity,
    filterContext.selectedGroupTags,
  ]);

  const dataFiltered = data?.dates || [];

  const dimensionCheck = (dimensionData?.dimensions || []).find(
    (d) => d.id === filterContext.selectedDimension
  );
  const dimensionName = dimensionCheck?.name || "All Dimensions";

  return (
    <>
      {tableLoading && <TableErrorState />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
              message: "No Data for the Selected Date Range",
              additionalMessage:
                "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
            },
            [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
              message: "No Data Available for Selected Filters",
              additionalMessage:
                "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}
      {!tableLoading && dataFiltered.length > 0 && (
        <>
          <div className={"table_main_container"}>
            <div className="all_cloud_text mb-3">Costs and usage breakdown</div>
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
              {false && filterContext?.selectedDimension.length === 0 && (
                <table style={{ width: "100%" }} className="table-hover">
                  <thead style={{ backgroundColor: "#f6f6f6" }}>
                    <tr>
                      <th
                        className={styles.table_class}
                        style={{
                          textAlign: "left",
                          position: "sticky",
                          left: 0,
                          backgroundColor: "#f6f6f6",
                        }}
                      >
                        All Dimensions
                      </th>
                      {(dataFiltered || []).map((item, index) => {
                        return (
                          <th
                            className={styles.table_class}
                            key={item + "-" + index}
                          >
                            {format(
                              parse(item, "dd-MM-yyyy", new Date()),
                              "dd LLL",
                              new Date()
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {(data?.data || []).slice(0, 1).map((e, index) => (
                      <tr
                        key={"date-" + e}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          backgroundColor:
                            hoveredRowIndex === index
                              ? "#F0F6FF"
                              : "transparent",
                        }}
                      >
                        <td
                          className={styles.tableData}
                          style={{
                            textAlign: "left",
                            fontWeight: "400",
                            position: "sticky",
                            left: 0,
                            backgroundColor:
                              hoveredRowIndex === index ? "#F0F6FF" : "#fff",
                          }}
                        >
                          Total Costs
                        </td>
                        {(dataFiltered || []).map((date) => (
                          <td
                            className={styles.tableData}
                            style={{ fontWeight: "400" }}
                          >
                            {e[date] ? Number(e[date]).toFixed(2) : "--"}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className={styles.tFoot}>
                      <td
                        colSpan={1000}
                        style={{ border: "none", padding: "8px" }}
                      >
                        *Figures in USD
                      </td>
                    </tr>
                  </tfoot>
                </table>
              )}

              {true && (
                <table style={{ width: "100%" }} className="table-hover">
                  <thead style={{ backgroundColor: "#f6f6f6" }}>
                    <tr>
                      <th
                        className={styles.table_class}
                        style={{
                          textAlign: "left",
                          position: "sticky",
                          left: 0,
                          backgroundColor: "#f6f6f6",
                          maxWidth: "350px",
                          textWrap: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                        title={dimensionName}
                      >
                        {dimensionName}
                      </th>
                      {(dataFiltered || []).map((item, index) => {
                        return (
                          <th
                            className={styles.table_class}
                            key={item + "-" + index}
                          >
                            {filterContext?.selectedGranularity === "date" &&
                              format(
                                parse(item, "dd-MM-yyyy", new Date()),
                                "dd LLL",
                                new Date()
                              )}
                            {filterContext?.selectedGranularity !== "date" &&
                              item}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {(data?.data || []).map((e, index) => (
                      <tr
                        key={"date-" + e}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          backgroundColor:
                            hoveredRowIndex === index
                              ? "#F0F6FF"
                              : "transparent",
                        }}
                      >
                        {e["Service"] !== "Dimensions" && (
                          <td
                            className={styles.tableData}
                            style={{
                              textAlign: "left",
                              fontWeight: "600",
                              left: 0,
                              position: "sticky",
                              cursor: "pointer",
                              color: "#0F2573",
                              maxWidth: "200px",
                              textWrap: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              backgroundColor:
                                hoveredRowIndex === index ? "#F0F6FF" : "#fff",
                              fontSize: "14px",
                            }}
                            onClick={() =>
                              handleUsageDetails(e, e["Service"] || "No Value")
                            }
                            title={e["Service"] || "No Value"}
                          >
                            {e["Service"] || "No Value"}
                          </td>
                        )}
                        {e["Service"] === "Dimensions" && (
                          <td
                            className={styles.tableData}
                            style={{
                              textAlign: "left",
                              fontWeight: "400",
                              position: "sticky",
                              left: 0,
                              backgroundColor:
                                hoveredRowIndex === index ? "#F0F6FF" : "#fff",
                            }}
                          >
                            Total Costs
                          </td>
                        )}
                        {(dataFiltered || []).map((date) => (
                          <td
                            className={styles.tableData}
                            style={{ textAlign: "right", fontWeight: "400" }}
                          >
                            {e[date] ? "$" + Number(e[date]).toFixed(2) : "--"}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className={styles.tFoot}>
                      <td
                        colSpan={1000}
                        style={{ border: "none", padding: "8px" }}
                      >
                        *Figures in USD
                      </td>
                    </tr>
                  </tfoot>
                </table>
              )}
            </div>
          </div>
          <Pagination totalItems={data?.total || 0} />
        </>
      )}
    </>
  );
};

export default ServiceTable;
