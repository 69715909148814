import React, { useMemo, useState } from "react";
import LineChart from "../LineChart";
import VBarChart from "../VBarChart";
import style from "@/page-components/cost-Recommendation/cost-Recommendation.module.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { usePojectedStatistics } from "@/services/cost-recommendation/getprojectedSctualStats.repo";
import { useProjectedDataDownlaod } from "@/services/cost-recommendation/getDownloadProjected.repo";
import VerticalBarGraph from "@/components/States/verticalBarErrorState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import TableAndGraph from "@/components/States/TableAndGraph";
import BarGraphErrorStates from "@/components/States/BarGraphErrorStates";

const ProjectedChartSection = () => {
  const query = useMemo(() => {
    return {
      service: "all-clouds",
    };
  }, []);
  const {
    data: projectedData,
    isLoading,
    error,
  } = usePojectedStatistics(query);

  // download-------------
  const downloadMutate = useMemo(() => {
    return {
      service: "all-clouds",
    };
  }, []);
  const { mutate: downloadMutateDiscard } =
    useProjectedDataDownlaod(downloadMutate);

  const handleDownloadClicked = () => {
    downloadMutateDiscard(query);
  };
  return (
    <>
      <div className={style.projectedChart}>
        <div
          style={{
            marginTop: "24px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span
            style={{ fontSize: "18px", fontWeight: "700", color: "#495057" }}
          >
            Projected/Actual Savings
          </span>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="tooltip">Download as CSV</Tooltip>}
          >
            <span
              className={styles.downloadButton}
              onClick={handleDownloadClicked}
            >
              <img src={downlaodVM} alt="" />
            </span>
          </OverlayTrigger>
        </div>

        
          {isLoading && (
            <div className={styles.wrapper}>
              <BarGraphErrorStates/>
            </div>
          )}
          {error && (
            <ScenariosErrorState
              error={error.message}
              messageConfig={{
                [API_NO_DATA_FOUND_ERROR]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
                [API_LOADING_ERROR]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
          )}
          {projectedData && projectedData.length > 0 && (
            <VBarChart projectedData={projectedData} />
          )}
        </div>
     
    </>
  );
};

export default ProjectedChartSection;
