import React, { useState, useMemo, useContext } from "react";
import "@/page-components/idle-resource/idle.module.css";
import copyicon from "@/assets/img/copyicon.svg";
import { useSearchParams } from "react-router-dom";
import { useIdleStatistics } from "@/services/saving/getTableIdleStatistics.repo";
import Pagination from "@/components/pagination/pagination.component";
import TableErrorState from "@/components/States/TableErrorState";
import styles from "./savings.module.css";
import clsx from "clsx";
import sorting from "@/assets/img/sorting.svg";
import { SavingContext } from "@/page-components/savings/components/saving-context-file.part";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import { useDownloadSavingData } from "@/services/saving/getDownloadData.repo";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { OverlayTrigger, Tooltip as ReactTooltip } from "react-bootstrap";

const SavingIdleTableComponent = ({ service, toDate, fromDate, name }) => {
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const [orderBy, setOrderBy] = useState("account_name");
  const desc = searchParams.get("desc");

  const handleCopyClick = (resource_id) => {
    const textarea = document.createElement("textarea");
    textarea.value = resource_id;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    setCopiedResourceId(resource_id);
    setShowCopyMessage(true);

    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };
  const context = useContext(SavingContext);

  const query = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );

    return {
      service,
      toDate,
      fromDate,
      page: parsedPage || 1,
      recordsPerPage: parsedItemsPerPage || 20,
      orderBy: orderBy || "account_name",
      desc: searchParams.get("desc") || "true",
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectRg?.length
          ? context?.selectRg.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags?.length ? tags : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context?.selectRg,
    context?.selectedTags,
    fromDate,
    itemsPerPage,
    orderBy,
    page,
    searchParams,
    service,
    toDate,
  ]);

  const { data, isLoading, error } = useIdleStatistics(query);

  const handleOrderByClick = (columnName) => {
    setOrderBy(orderBy === columnName ? columnName : columnName);
    const newBoolVal = desc === "true" ? "false" : "true";

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", orderBy);
    updatedSearchParams.set("desc", newBoolVal);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  const downloadQuery = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );

    return {
      service,
      toDate,
      fromDate,
      downloadService: "saving-details-download",
      page: parsedPage || 1,
      recordsPerPage: parsedItemsPerPage || 20,
      orderBy: orderBy || "account_name",
      desc: searchParams.get("desc") || "true",
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectRg?.length
          ? context?.selectRg.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags?.length ? tags : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context?.selectRg,
    context?.selectedTags,
    fromDate,
    itemsPerPage,
    orderBy,
    page,
    searchParams,
    service,
    toDate,
  ]);
  const { mutate: downloadMutate } = useDownloadSavingData(downloadQuery);

  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };

  return (
    <>
      {isLoading && <TableErrorState />}
      {error && (
        <div className="main_cont_padding">

          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                message: "No Data Available for Selected Filters",
                additionalMessage:
                  "It looks like there are no savings data matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
              [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
                message: "No Savings Data for the Selected Date Range",
                additionalMessage:
                  "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
              },
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the savings data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        </div>
      )}

      {data?.count?.total_data > 0 && (
        <div
          className={clsx(
            styles.service_table__container1,
            "main_cont_padding"
          )}
        >
          <div className={styles.menu_space_between}>
            <div className={styles.all_cloud_text}>
              Saving Detail ({data?.count?.total_data || 0} Resources)
            </div>
            <div className={styles.menu_icon_css1}>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <ReactTooltip id="tooltip">Download as CSV</ReactTooltip>
                }
              >
                <span
                  className={styles.downloadButton}
                  onClick={handleDownloadClick}
                >
                  <img src={downlaodVM} alt="" />
                </span>
              </OverlayTrigger>
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <div
              className="container-fluid table_main_container p-0"
              style={{ minWidth: "30%", overflowX: "auto" }}
            >
              <table
                className="table table-hover"
                id={styles.customers}
                style={{}}
              >
                <thead>
                  <tr>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      {name}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("account_name")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Resource Name
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("resource_name")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Resource Id
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("resource_id")}
                      ></img>
                    </th>
                    {service === "azure" && (
                      <th
                        className={clsx(styles.th_class, styles.table_td_width)}
                      >
                        Resource Group
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() => handleOrderByClick("resource_group")}
                        ></img>
                      </th>
                    )}
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Resource Type
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("resource_type")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Region
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("region")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                      style={{ textAlign: "center" }}
                    >
                      Idle Days
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("idle_days")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                      style={{ textAlign: "end" }}
                    >
                      Available Savings
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("available_saving")}
                      ></img>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data.map((item, index) => (
                    <tr key={item.resource_id} className={styles.table_row}>
                      <td
                        className={styles.table_data__Class}
                        title={item?.account_name}
                      >
                        {item?.account_name || "NA"}
                      </td>
                      <td
                        className={styles.table_data__Class}
                        title={item?.resource_name}
                      >
                        {item?.resource_name || "NA"}
                      </td>
                      <td
                        className={styles.table_data__Class}
                        style={{ position: "relative" }}
                        title={item?.resource_id}
                      >
                        <span>{item?.resource_id || "NA"}</span>
                        <button
                          className={`${styles.copy_icon} ${
                            copiedResourceId === item?.resource_id
                              ? "visible"
                              : ""
                          }`}
                          onClick={() => handleCopyClick(item?.resource_id)}
                        >
                          <img
                            src={copyicon}
                            alt=""
                            style={{ marginRight: "-15px" }}
                          />
                        </button>
                      </td>
                      {service === "azure" && (
                        <td className={styles.table_data__Class}>
                          {item?.resource_group || "NA"}
                        </td>
                      )}
                      <td className={styles.table_data__Class}>
                        {item?.resource_type || "NA"}
                      </td>

                      <td className={styles.table_data__Class}>
                        {item?.region || "NA"}
                      </td>
                      <td
                        className={styles.table_data__Class}
                        style={{ textAlign: "center" }}
                      >
                        {item?.idle_days || "NA"}
                      </td>
                      <td
                        className={styles.table_data__Class}
                        style={{
                          textAlign: "right",
                        }}
                      >
                        ${Number(item?.available_saving).toFixed(2) || 0}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination totalItems={data?.count?.total_data || 0} />
        </div>
      )}
      {showCopyMessage && (
        <div className={styles.copyMessage}>Copy to Clipboard!</div>
      )}
    </>
  );
};

export default SavingIdleTableComponent;
