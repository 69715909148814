import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import style from "@/page-components/cost-Recommendation/cost-Recommendation.module.css";
import clsx from "clsx";
import Pagination from "@/components/pagination/pagination.component";
import { useCostRecommendationsList } from "@/services/cost-recommendation/getTableStats.repo";
import { CostRecommendationContext } from "../costContext";
import { useSearchParams } from "react-router-dom";
import copyicon from "@/assets/img/copyicon.svg";
import { Offcanvas, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import sorting from "@/assets/img/sorting.svg";

import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import TableErrorState from "@/components/States/TableErrorState";
import RecommendationModal from "./modal/recommendationModal";

const ActiveCostTable = ({ service }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");

  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const recommendation_id = searchParams.get("recommendation_id");
  const [actionType, setActionType] = useState("");
  const [summary, setsummary] = useState("");
  const desc = searchParams.get("desc");

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
    ? parseInt(itemsPerPage)
    : 10;

  const context = useContext(CostRecommendationContext);
  const handleCopyClick = (event, resource_Id) => {
    event.stopPropagation();
    const textarea = document.createElement("textarea");
    textarea.value = resource_Id;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopiedResourceId(resource_Id);
    setShowCopyMessage(true);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };

  const query = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service,
      page: parsedPage || 1,
      recordsPerPage: parsedItemsPerPage || 10,
      orderBy: context?.orderBy,
      desc: searchParams.get("desc") || "true",
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      // resourceGroupName:
      //   context?.selectedResourceGroup?.length > 0 &&
      //   context?.selectedResourceGroup?.length
      //     ? context.selectedResourceGroup.map((e) => e.id)
      //     : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      resource_type: context?.selectedResourceType?.length
        ? context.selectedResourceType.map((e) => e.id)
        : undefined,
      impact: context?.selectedImpact?.length
        ? context.selectedImpact.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
    };
  }, [
    context?.selectedTags,
    context?.orderBy,
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedResourceType,
    context.selectedImpact,
    service,
    parsedPage,
    parsedItemsPerPage,
    searchParams,
  ]);

  const {
    data: tableData,
    isLoading,
    error,
  } = useCostRecommendationsList(query);

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const showRecommendationModal = (recommendation_id) => {
    searchParams.set("recommendation_id", recommendation_id);
    setSearchParams(searchParams);
  };
  useEffect(()=>{
    searchParams.delete("recommendation_id");
    setSearchParams(searchParams);
  },[])
  const handleOrderByClick = (columnName) => {
    context?.setOrderBy(
      context?.orderBy === columnName ? columnName : columnName
    );
    const newBoolVal = desc === "true" ? "false" : "true";

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", context?.orderBy);
    updatedSearchParams.set("desc", newBoolVal);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  return (
    <>
      {/* {toast && (
        <ToastComponent
          message={message}
          toast={toast}
          setToast={setToast}
          timeout={5000}
        />
      )} */}

      {isLoading && (
        <div className={styles.wrapper}>
          <TableErrorState />
        </div>
      )}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
              message: "No Data Available for Selected Filters",
              additionalMessage:
                "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {tableData?.data.length > 0 && (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={style.scroll}>
              <table
                id="customers"
                className={clsx(styles.scrollable, "table-hover")}
              >
                <thead>
                  <tr>
                  {service === "azure" && (
                      <th className={styles.tableHead} style={{ width: "8%" }}>
                        Impacted Resource
                      </th>
                    )}
                    {service === "aws" && (

                    <th className={styles.tableHead} style={{ width: "20%" }}>
                    Recommended Action
                    </th>
                    )}
                    <th className={styles.tableHead} style={{ width: "11%" }}>
                      {service === "aws" && "Account Name"}
                      {service === "azure" && "Subscription Name"}
                    </th>
                    <th className={styles.tableHead} style={{ width: "15%" }}>
                      Region
                    </th>
                   {service === "aws" && ( <th className={styles.tableHead} style={{ width: "7%" }}>
                      Monthly Cost
                      <img
                        style={{ cursor: "pointer" }}
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("monthly_cost")}
                      ></img>
                    </th>)}
                    <th className={styles.tableHead} style={{ width: "20%" }}>
                      Resource Type
                    </th>
                    {service === "azure" && (
                    <th className={styles.tableHead} style={{ width: "8%" }}>
                      Recommended Action
                    </th>
                    )}
                    <th className={styles.tableHead} style={{ width: "8%" }}>
                      {service === "aws" && "Recommended Resource Summary"}
                      {service === "azure" && "Lookback Period Days"}
                    </th>

                    {service === "azure" &&(
                    <th className={styles.tableHead} style={{ width: "8%" }}>
                      Impact
                    </th>
                    )}
                    <th
                      className={styles.tableHead}
                      style={{ width: "10%" }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && (
                    <tr>
                      <td colSpan={9}>
                        <div
                          style={{
                            width: "100%",
                            marginBottom: 40,
                            marginTop: 40,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Spinner
                            animation="border"
                            variant="primary"
                            style={{ top: "50" }}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                  {(tableData?.data || []).map((item, i) => (
                    <tr
                      id={styles.table_body_tr}
                      className={clsx(styles.accountCol, {
                        [styles.hovered]: i === hoveredRowIndex,
                      })}
                      key={item.resourceId + "-" + i}
                      onMouseEnter={() => handleMouseEnter(i)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {service === "aws" &&(<td
                        className={clsx(
                          styles.accountCol,
                          styles.accountColNowrap,
                          styles.accountTitleCol,
                          styles.accountTitleColData,
                          styles.my_white_space
                        )}
                      >
                        <span
                          style={{
                            color: "#056EE5",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            showRecommendationModal(item?.recommendation_id);
                            setActionType(item?.recommended_action);
                            setsummary(item?.recommended_resource_summary);
                          }}
                          >
                            
                          <OverlayTrigger
                          className={style.customTooltipMAin}

                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip" className={style.customTooltip}>
                                {item?.recommended_action}
                              </Tooltip>
                            }
                          >
                            <span>
                            {item?.recommended_action !== null
                              ? item?.recommended_action || "--"
                              : 0}
                            </span>
                          </OverlayTrigger>
                          </span>
                          
                        </td>
                      )}
                      {service === "azure" && (
                        <td
                          onClick={() => {
                            showRecommendationModal(item?.recommendation_id);
                            setActionType(item?.recommended_action);
                            setsummary(item?.recommended_resource_summary);
                          }}
                          className={styles.accountCol}
                          style={{
                            textWrap: "nowrap",
                            color: "#056EE5",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip className={style.customTooltip}>
                                {item?.subscription_name ??
                                  item?.impacted_value ??
                                  "--"}
                              </Tooltip>
                            }
                          >
                            <span>
                              {item?.subscription_name ??
                                item?.impacted_value ??
                                "--"}
                            </span>
                          </OverlayTrigger>
                        </td>
                      )}

                      <td
                        className={clsx(
                          styles.accountCol,
                          styles.accountColNowrap,
                          // styles.accountTitleCol,
                          // styles.accountTitleColData
                        )}
                      >
                        <span>
                          {(item.account_name || item?.subscription_name) !==
                          null
                            ? item.account_name ||
                              item?.subscription_name ||
                              "--"
                            : "--"}
                        </span>
                      </td>
                      <td
                        className={clsx(
                          styles.accountCol,
                          styles.my_white_space
                        )}
                      >
                        {item?.region !== null ? item?.region || "--" : "--"}
                      </td>

                      {service === "aws" && (
                        <td
                          className={clsx(
                            styles.accountCol,
                            styles.my_white_space
                          )}
                        >
                          {item?.monthly_cost !== null
                            ? "$" +
                              Number(item?.monthly_cost || "--").toFixed(2)
                            : "--"}
                        </td>
                      )}
                      <td className={styles.accountCol}>
                        {(item?.current_resource_type ||
                          item?.current_vm_family) !== null
                          ? item?.current_resource_type ||
                            item?.current_vm_family ||
                            "--"
                          : "--"}
                      </td>
                      {service ==="azure" && (
                      <td className={styles.accountCol}>
                        <span
                          style={{ textWrap: "nowrap" }}
                          className={clsx(
                            item?.recommended_action ===
                              "PurchaseReservedInstances" &&
                              style.PurchaseReservedInstances,
                            item?.recommended_action ===
                              "PurchaseSavingsPlans" &&
                              style.PurchaseSavingsPlans,
                            item?.recommended_action === "Rightsize" &&
                              style.Rightsize,
                            item?.recommended_action === "MigrateToGraviton" &&
                              style.MigrateToGraviton,
                            item?.recommended_action === "Upgrade" &&
                              style.Upgrade
                          )}
                        >
                          {item?.recommended_action !== null
                            ? item?.recommended_action || "--"
                            : "--"}
                        </span>
                      </td>
                      )}
                      {/* <td className={styles.accountCol}>
                        {item?.recommended_resource_type !== null
                          ? item?.recommended_resource_type || "--"
                          : "--"}
                      </td> */}
                   
                      <td
                        className={styles.accountCol}
                        style={{ textWrap: "nowrap" }}
                      >
                        {service === "aws" &&
                          (item?.recommended_resource_summary !== null
                            ? item?.recommended_resource_summary || "--"
                            : "--")}
                        {service === "azure" &&
                          (item?.lookback_period_days !== null
                            ? item?.lookback_period_days || "--"
                            : "--")}
                      </td>
                      {service === "azure" && (
                        <td
                          className={styles.accountCol}
                          style={{ textWrap: "nowrap" }}
                        >
                          {item?.impact || "--"}
                        </td>
                      )}
                      <td style={{ width: "100px" }}></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={style.fixed}>
                <table
                  id="customers"
                  style={{ width: "100%" }}
                  className="table-hover"
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          textAlign: "end",
                        }}
                        className={clsx(
                          styles.tableHead,
                          styles.accountCol_center
                        )}
                      >
                        Projected Monthly Saving
                        <img
                          style={{ cursor: "pointer" }}
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() => handleOrderByClick("monthly_saving")}
                        ></img>
                      </th>
                      {/* {service === "aws" && (

                      <th
                        className={clsx(
                          styles.tableHead,
                          styles.accountCol_center
                        )}
                        style={{
                          textAlign: "center",
                          paddingRight: "10px",
                        }}
                      >
                        Saving %
                        <img
                          style={{ cursor: "pointer" }}
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() => handleOrderByClick("saving")}
                        ></img>
                      </th>
                      )} */}
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      (tableData?.data || []).map((item, i) => (
                        <tr
                          style={{ position: "relative" }}
                          className={clsx(styles.accountCol, {
                            [styles.hovered]: i === hoveredRowIndex,
                          })}
                          key={item.resourceId + "-" + i}
                          onMouseEnter={() => handleMouseEnter(i)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <td
                            style={{ textAlign: "center" }}
                            className={clsx(
                              styles.accountCol,
                              styles.accountCol_weight,

                              styles.accountCol_end,
                              Number(item?.monthly_saving || 0) < 0 &&
                                styles.accountCol_red,
                              Number(item?.monthly_saving || 0) > 0 &&
                                styles.accountCol_green
                            )}
                          >
                            {item?.monthly_saving !== null
                              ? "$" +
                                Number(item?.monthly_saving || "--").toFixed(2)
                              : "--"}
                          </td>
                          {/* {service === "aws" && (
                            
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountCol_weight,
                              styles.accountCol_center
                            )}
                          >
                            {item?.saving !== null
                              ? !Number.isInteger(Number(item?.saving))
                                ? Number(item?.saving || "--").toFixed(2) + "%"
                                : Number(item?.saving || "--") + "%"
                              : "--"}
                          </td>
                          )} */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            <Pagination totalItems={tableData?.count?.total_data || 0} />
          </div>
          {showCopyMessage && (
            <div className={styles.copyMessage}>Copy to Clipboard!</div>
          )}
        </div>
      )}
      <Offcanvas
        placement="end"
        show={recommendation_id}
        onHide={() => {
          searchParams.delete("recommendation_id");
          setSearchParams(searchParams);
        }}
        style={{
          width: "70%",
        }}
      >
        <Offcanvas.Body
          className={styles.modal_body}
          style={{
            paddingTop: 16,
          }}
        >
          <RecommendationModal
            resourceId={recommendation_id}
            service={service}
            action_type={actionType}
            summary={summary}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ActiveCostTable;
