import React, { useContext, useEffect, useMemo } from "react";
import styles from "@/page-components/billing-credit/billing-credit.module.css";
import Dropdown from "react-bootstrap/Dropdown";
import { BillingContext } from "@/page-components/billing-credit/billing-context.part";
import CreditBalanceComponent from "./credit-balance-component.part";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import { useStatsHeaderStatistics } from "@/services/billing-credit/getStatisticsHeader.repo";
import clsx from "clsx";
import HeaderState from "@/components/States/HeaderState";
import { formatCount } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";

const HeaderComponent = ({ service }) => {
  const context = useContext(BillingContext);

  const handleDropdownChange = (eventKey) => {
    context?.setAccountDropdown(eventKey);
  };
  const query = useMemo(() => {
    if (!context?.accountType) {
      return null;
    }
    return {
      service: service,
      accountType: context?.accountType,
    };
  }, [context?.accountType, service]);

  const {
    data: totalStats,
    isLoading,
    error,
  } = useStatsHeaderStatistics(query);

  useEffect(() => {
    if (totalStats?.data?.rootAccountNumber) {
      context?.setRootAccountNumber(totalStats?.data?.rootAccountNumber);
    } else {
      context?.setRootAccountNumber("--");
    }
  }, [context, totalStats]);

  const data = {
    "TOTAL CHARGES": `${totalStats?.data?.totalCharges || 0}`,
    "CREDITS APPLIED": `${totalStats?.data?.creditApplied || 0}`,
    "CURRENT BALANCE": `${totalStats?.data?.currentBalance || 0}`,
  };

  return (
    <>
      <div
        className={
          (!isLoading && totalStats) || error ? styles.main_containers : ""
        }
      >
        {((!isLoading && totalStats) || error) && (
          <div className={styles.billing_header}>
            <span className={styles.billing_name}>Billing + Credit</span>
            {service !== "aws" && (
              <div className={styles.account_dropdown}>
                <span className={styles.account_type}>Account Type</span>
                <div className={styles.header_dropdown}>
                  <Dropdown onSelect={handleDropdownChange}>
                    <Dropdown.Toggle
                      variant="none"
                      id={styles.selected_value}
                      style={{ color: "white", border: "none" }}
                    >
                      {context?.accountDropdown}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles.dropdown_menu}>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        eventKey="Enterprise Agreement"
                      >
                        Enterprise Agreement
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        eventKey="Microsoft Customer Agreement"
                      >
                        Microsoft Customer Agreement
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            )}
          </div>
        )}
        {/* -------------------main component-------------------------- */}
        {isLoading && <HeaderState />}
        {error && (
          <ScenariosErrorState
            error={error.message}
            headerItem="header"
            messageConfig={{
              [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                message: "No Data Available",
                additionalMessage:
                  "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
              [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        )}
        {totalStats && (
          <div className={styles.component_css}>
            <div className={styles.data_bars}>
              <div className={styles.date_total}>
                <span className={styles.total_name}>TOTAL</span>
                <span className={styles.total_month}>Last 3 months</span>
              </div>
              <div className={styles.azzount_box}>
                <span className={styles.account_name}> ORG ACCOUNT NAME</span>
                <span
                  className={clsx(styles.account_value, styles.trucate_value)}
                  title={
                    totalStats?.data?.account_name ||
                    totalStats?.data?.rootAccountName
                  }
                >
                  {totalStats?.data?.account_name ||
                    totalStats?.data?.rootAccountName ||
                    "--"}
                </span>
              </div>
              <div></div>
            </div>
            {/* ------------------------------- */}
            <div className={styles.second_comp}>
              {Object.keys(data).map((key, index) => {
                const value = data[key];

                const formattedValue = Math.abs(value).toFixed(2);
                const formatNewValue = formatCount(Number(formattedValue));
                const formattedValueWithSign =
                  value < 0 ? `-$${formatNewValue}` : `$${formatNewValue}`;
                const formattedValueTooltip = value;

                return (
                  <React.Fragment key={key}>
                    <div className={styles.azzount_box}>
                      <span className={styles.account_name}>{key}</span>
                      <span>
                          <span className={styles.values_css}>
                        <ToolTipComponent data={formattedValueTooltip}>
                            {formattedValueWithSign}
                        </ToolTipComponent>
                          </span>
                      </span>
                    </div>
                    {index !== Object.keys(data).length - 1 && (
                      <div className={styles.vertical_line}></div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            {/* ---------------------------------------------------- */}

            {/* <div style={{ width: "35%" }}>
                <CreditBalanceComponent service={service} />
              </div> */}
          </div>
        )}

        {/* -------------------------------------------------------- */}
        <div className={styles.updated_box}>
          <span className={styles.updated_date}>Updated Today at 7:05AM</span>
        </div>
      </div>
    </>
  );
};

export default HeaderComponent;
