import React, {
  Children,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useFilterOptions } from "@/services/snapshots/getAzureFilter.repo";
import { SnapshotContext } from "../../snap.contex";
import styles from "@/page-components/snapshot/snapshots.module.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import arrow from "@/assets/img/arrow.svg";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import TagItem from "./tag-item.part";
import add from "@/assets/img/add.svg";
import { Button } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDebouncedValue } from "rooks";
import Form from "react-bootstrap/Form";

const FilterSection = ({ service, name }) => {
  const context = useContext(SnapshotContext);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const [tempValue, setTempValue] = useState([7, 250]);

  // const onValuesChange = (values) => {
  //   setTempValue(values);
  // };

  function removeLeadingZeros(input) {
    return Number(input).toString();
  }
  useEffect(()=>{
    setTempValue(context?.value)

  },[])

  const SliderTooltip = ({ children }) => {
    const themeTooltip = {
      color: "#343A40",
      fontSize: "10px",
      fontWeight: "600",
      whiteSpace: "nowrap",
      position: "relative",
      bottom: "100%",
      transform: "translate(50%, 25px)",
    };

    return <div style={themeTooltip}>{children}</div>;
  };

  const days = tempValue[1] - tempValue[0];

  const selectedAccountIds = (context.selectedAccounts || []).map((e) => e.id);

  const query = useMemo(() => {
    const tags = (context?.selectedSnapshotTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service,
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      diskIds: context?.selectedDisk?.length
        ? context.selectedDisk.map((e) => e.id)
        : undefined,
      snapshotAge: context?.selectedSnapshotAge?.length
        ? context.selectedSnapshotAge.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
      minAge: context?.value?.length ? context?.value[0] : undefined,
      maxAge: context?.value?.length ? context?.value[1] : undefined,
      resourceGroupNames: context?.selectedResourceGroup?.length
        ? context?.selectedResourceGroup.map((e) => e.id)
        : undefined,
    };
  }, [
    context.selectedAccounts,
    context.selectedDisk,
    context.selectedRegions,
    context.selectedSnapshotAge,
    context?.selectedSnapshotTags,
    context?.selectedResourceGroup,
    context?.value,
    service,
  ]);

  const [debouncedSnapshotQuery] = useDebouncedValue(query, 500);

  const dropdown = useFilterOptions(query);

  const onTempValueChange = (value) => {
    setTempValue(value);
  };

  const handleApply = () => {
    context?.setValue(tempValue);
  };

  useEffect(() => {
    if (context.selectedAccounts) {
      context?.setSelectedResourceGroup([]);
    }
  }, [context.selectedAccounts]);

  useEffect(() => {
    const disableAddButton = context.selectedSnapshotTags.some(
      (tag) => tag.filterOn === "" || tag.values.length === 0
    );
    setIsAddButtonDisabled(disableAddButton);
  }, [context.selectedSnapshotTags]);

  const navigate = useNavigate();
  const handleTagSelection = () => {
    // Reset page to 1 when a filter is selected
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (tempValue[0] < 7) {
      alert("Minimum value must be at least 7");
    } else if (tempValue[1] < tempValue[0]) {
      alert("Maximum value must be greater then minimum value");
    } else {
      handleApply();
      handleTagSelection();
    }
  };

  return (
    <>
      <div className={styles.filter_main_div}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <div className={styles.untagged_class}>
            <Form.Check
              aria-label="option"
              checked={context?.showOrphanedOnly}
              onChange={(e) => {
                context?.setShowOrphanedOnly(e.target.checked);
                handleTagSelection();
              }}
            />
            <span className={styles.untagged}>Show Orphaned Snapshots</span>
          </div>

          <div className={styles.untagged_class}>
            <Form.Check
              aria-label="option"
              checked={context?.legallyHeldSnapshot}
              onChange={(e) => {
                context?.setLegallyHeldSnapshot(e.target.checked);
                handleTagSelection();
              }}
            />
            <span className={styles.untagged}>Show Legally Held Only</span>
          </div>
          <hr className="w-100 mb-0 mt-0" />
          <Filter
            tittle={name}
            data={dropdown?.data?.accountData}
            filterType="account"
            selectedValues={context.selectedAccounts}
            onSelect={(list) => {
              context?.setSelectedAccounts(list);
            }}
            onRemove={(list) => {
              context?.setSelectedAccounts(list);
            }}
          />

          {service === "azure" && context?.selectedAccounts?.length > 0 && (
            <Filter
              tittle={"Resource Group"}
              data={dropdown?.data?.resourceGroupNames}
              filterType="resourcegroupname"
              selectedValues={context.selectedResourceGroup}
              onSelect={(list) => {
                context?.setSelectedResourceGroup(list);
              }}
              onRemove={(list) => {
                context?.setSelectedResourceGroup(list);
              }}
            />
          )}

          <Filter
            tittle={"Region"}
            data={dropdown?.data?.regionData}
            filterType="region"
            selectedValues={context.selectedRegions}
            onSelect={(list) => {
              context?.setSelectedRegions(list);
            }}
            onRemove={(list) => {
              context?.setSelectedRegions(list);
            }}
          />

          <Filter
            tittle={"Disk ID"}
            data={dropdown?.data?.diskIdData}
            filterType="Disk_ID"
            selectedValues={context.selectedDisk}
            onSelect={(list) => {
              context?.setSelectedDisk(list);
            }}
            onRemove={(list) => {
              context?.setSelectedDisk(list);
            }}
          />

          <div>
            <div
              className={styles.dimension_name_css}
              style={{ flex: "none", width: "90%" }}
            >
              Snapshot Age
            </div>
            <div
              className={styles.filter_select_box}
              style={{
                padding: "8px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className={styles.ageRange}>Age Ranger</span>
              <Slider
                range
                min={7}
                max={250}
                value={tempValue}
                onChange={onTempValueChange}
                styles={{
                  track: { backgroundColor: "#056EE5", color: "red" },
                  handle: {
                    backgroundColor: "#056EE5",
                    borderColor: "#056EE5",
                    opacity: 1,
                  },
                }}
                allowCross={false}
                handleRender={(renderProps, { value }) => {
                  return (
                    <div {...renderProps.props}>
                      <SliderTooltip>{value}</SliderTooltip>
                    </div>
                  );
                }}
              />
              <span className={styles.daysDifference}>{days || "NA"} Days</span>

              <div className={styles.minMax}>
                <form
                  style={{ display: "flex", gap: "8px" }}
                  onSubmit={handleSubmit}
                >
                  <span className={styles.minMaxText}>
                    Min
                    <input
                      type="number"
                      className={styles.inputStyle}
                      placeholder="1"
                      min={7}
                      // max={250}
                      value={removeLeadingZeros(tempValue[0])}
                      onChange={(e) =>
                        setTempValue([Number(e.target.value), tempValue[1]])
                      }
                    />
                  </span>

                  <span className={styles.minMaxText}>
                    Max
                    <input
                      type="number"
                      className={styles.inputStyle}
                      placeholder="250"
                      min={7}
                      // max={250}
                      value={removeLeadingZeros(tempValue[1])}
                      onChange={(e) =>
                        setTempValue([tempValue[0], Number(e.target.value)])
                      }
                    />
                  </span>
                  <Button type="submit" className={styles.applyButtonAge}>
                    <img src={arrow} alt="" />
                  </Button>
                </form>
              </div>
            </div>
          </div>

          <hr className="w-100 mb-0" />

          <div className={styles.filter_box_div}>
            <div className={styles.filter_name_addsign}>
              <div className={styles.filter_name}>Tags</div>
              <Button
                variant="link"
                className="p-0"
                onClick={() => {
                  context.setSelectedSnapshotTags((arr) => {
                    const newArr = [...arr];
                    newArr.push({
                      filterOn: "",
                      filterType: "include",
                      values: [],
                    });
                    return newArr;
                  });
                }}
                disabled={context.showUntaggedOnly || isAddButtonDisabled}
                style={{
                  opacity:
                    context.showUntaggedOnly || isAddButtonDisabled ? 0.4 : " ",
                  fill:
                    context.showUntaggedOnly || isAddButtonDisabled
                      ? "#CED4DA"
                      : "",
                  pointerEvents:
                    context.showUntaggedOnly || isAddButtonDisabled
                      ? "none"
                      : "auto",
                }}
              >
                <img src={add} alt="" />
              </Button>
            </div>

            <div
              className={styles.untagged_class}
              style={{ marginLeft: "8px" }}
            >
              <Form.Check
                aria-label="option"
                checked={context.showUntaggedOnly}
                onChange={(e) => {
                  context.setShowUntaggedOnly(e.target.checked);
                  handleTagSelection();
                }}
              />
              <span className={styles.untagged}>Show Untagged Only</span>
            </div>

            {!context.showUntaggedOnly &&
              context.selectedSnapshotTags.map((tag, index) => (
                <TagItem service={service} tag={tag} index={index} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
const Filter = ({
  tittle,
  data,
  setSelectedValues,

  filterType,
  selectedValues,
  onSelect,
  onRemove,
}) => {
  const context = useContext(SnapshotContext);

  const handleSelect = (selectedList) => {
    if (filterType === "account") {
      context.setSelectedAccounts(selectedList);
    }
    if (filterType === "region") {
      context.setSelectedRegions(selectedList);
    }
    if (filterType === "Snapshot_Age") {
      context.setSelectedSnapshotAge(selectedList);
    }
    if (filterType === "Disk_ID") {
      context.setSelectedDisk(selectedList);
    }
    if (filterType === "resourcegroupname") {
      context.setSelectedResourceGroup(selectedList);
    }
  };

  const handleRemove = (selectedList) => {
    handleSelect(selectedList);
  };

  const handleTittle = (title) => {
    if (title === "Subscription") {
      title = "Account";
    }
  };

  const navigate = useNavigate();
  const handleTagSelection = () => {
    // Reset page to 1 when a filter is selected
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <>
      <div className={styles.filter_padding_div}>
        <div className={styles.dimension_div}>
          <div className={styles.dimension_name_css}>{tittle}</div>
          <div
            onClick={() => handleTittle(tittle)}
            className={styles.filter_group_name_css}
          >
            <FilterSearch
              multiselect={true}
              options={(data || []).map((e) => {
                return {
                  id:
                    e.accountId ||
                    e.regionId ||
                    e.ageId ||
                    e.diskId ||
                    e.resourceGroup ||
                    e.ProjectId,
                  name:
                    e.accountName ||
                    e.region ||
                    e.age ||
                    e.diskName ||
                    e.resourceGroupName ||
                    e.ProjectName,
                };
              })}
              selectedValues={selectedValues || []}
              onSelect={(list) => {
                handleTagSelection();
                handleSelect();
                handleRemove(list);
              }}
              onRemove={(list) => {
                handleRemove(list);
              }}
              displayValue="name"
              placeholder="--Select--"
              toggle={
                <>
                  <FilterSearchToggle placeholder={tittle} />
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterSection;
