import React, {
  useState,
  createContext,
  useContext,
  useMemo,
  useEffect,
} from "react";
import "@/assets/css/filter.css";
import add from "@/assets/img/add.svg";
import subDays from "date-fns/subDays";

import { GRANULARITY } from "@/services/costExplorer/addCostExplorerWidget.repo";
import { Button } from "react-bootstrap";
import styles from "./filter-section.module.css";
import FilterItem from "./filter-section-item.part";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import TagItem from "./tag-item.part";
import { useDimensionTagsKeys } from "@/services/costExplorer/getDimensionTags.repo";
import Tooltip from "@/components/tooltip/tooltip";
import FilterState from "@/components/States/FilterState";

/**
 * @typedef {import('@/services/costExplorer/addCostExplorerWidget.repo').AddCostExplorerWidgetReqFilters} FilterItem
 */

/**
 * @typedef {import('@/services/costExplorer/addCostExplorerWidget.repo').AddCostExplorerWidgetReqTags} TagItem
 */

/**
 * @typedef FilterContext
 * @property {string} selectedDimension
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectedDimension
 * @property {FilterItem[]} selectedGroupTags
 * @property {React.Dispatch<React.SetStateAction<FilterItem[]>>} setSelectedGroupTags
 * @property {string} selectUsageDimension
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectUsageDimension
 * @property {FilterItem[]} selectedFilters
 * @property {React.Dispatch<React.SetStateAction<FilterItem[]>>} setSelectedFilters
 * @property {TagItem[]} selectedTags
 * @property {React.Dispatch<React.SetStateAction<TagItem[]>>} setSelectedTags
 * @property {string} selectedGranularity
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectedGranularity
 * @property {string} selectedCloud
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectedCloud
 * @property {string} selectedservice
 * @property {React.Dispatch<React.SetStateAction<string>>} setselectedservice
 * @property {string[]} selectedaccount
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setselectedaccount
 * @property {string[]} selectedregion
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setselectedregion
 * @property {Object} range
 * @property {React.Dispatch<React.SetStateAction<{ startDate: Date; endDate: Date; key: string;}[]>>} setRange
 * @property {string[]} untagged
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setUntagged
 * @property {string[]} untaggable
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setShowUntaggable
 * @property {string[]} selectForecastAccount
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectForecastAccount
 */

/** @type {React.Context<FilterContext>} */
export const FilterContext = createContext(null);

export const FilterContextProvider = ({ children }) => {
  const [selectedFilters, setSelectedFilters] = useState([
    {
      dimension: "",
      isInclude: true,
      values: [],
    },
  ]);

  const [selectedTags, setSelectedTags] = useState([
    {
      filterOn: "",
      filterType: "include",
      values: [],
    },
  ]);

  const [selectedDimension, setSelectedDimension] = useState("");
  const [selectedGroupTags, setSelectedGroupTags] = useState();
  const [selectUsageDimension, setSelectUsageDimension] = useState("");
  const [selectedGranularity, setSelectedGranularity] = useState("date");
  const [selectedDataLength, setSelectedDataLength] = useState([]);
  const [selectedCloud, setSelectedCloud] = useState();
  const [selectedservice, setselectedservice] = useState();
  const [selectedaccount, setselectedaccount] = useState([]);
  const [selectedregion, setselectedregion] = useState([]);
  const [untagged, setUntagged] = useState(false);
  const [untaggable, setShowUntaggable] = useState(false);
  const [selectForecastAccount, setSelectForecastAccount] = useState([]);

  const [range, setRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: subDays(new Date(), 2),
      key: "selection",
    },
  ]);

  return (
    <FilterContext.Provider
      value={{
        range,
        setRange,
        selectedFilters,
        setSelectedFilters,
        selectedTags,
        setSelectedTags,
        selectedDimension,
        setSelectedDimension,
        selectedGranularity,
        setSelectedGranularity,
        selectedDataLength,
        untagged,
        setUntagged,
        untaggable,
        setShowUntaggable,
        setSelectedDataLength,
        selectedservice,
        setselectedservice,
        selectedaccount,
        setselectedaccount,
        selectedregion,
        setselectedregion,
        selectedCloud,
        setSelectedCloud,
        selectUsageDimension,
        setSelectUsageDimension,
        setSelectedGroupTags,
        selectedGroupTags,
        selectForecastAccount,
        setSelectForecastAccount,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

const 
FilterSection = ({ service, enableBudgetDetails, dimensionData }) => {
  const filterContext = useContext(FilterContext);

  const filterDimensionNames = useMemo(() => {
    const retrivalNames = (data) => {
      return (data || []).reduce((acc, cur) => {
        const dimension = dimensionData?.dimensions.find(
          (d) => d.name === cur.name
        );
        if (dimension) {
          acc.push({ id: dimension.id, name: dimension.name });
        }
        return acc;
      }, []);
    };

    return {
      dimensionsAzure: retrivalNames(
        enableBudgetDetails?.Details?.filters?.dimensions
      ),
    };
  }, [enableBudgetDetails?.Details?.filters?.dimensions, dimensionData]);

  const filterNames = useMemo(() => {
    const retrivalNames = (data) => {
      const dimensionLookup = (dimensionData?.dimensions || []).reduce(
        (acc, cur) => {
          acc[cur.id] = cur.name;
          return acc;
        },
        {}
      );

      return (data || []).reduce((acc, cur) => {
        if (dimensionLookup[cur.name]) {
          acc[dimensionLookup[cur.name]] = cur.name;
        }
        return acc;
      }, {});
    };

    return {
      dimensionsAzure: retrivalNames(
        enableBudgetDetails?.Details?.filters?.dimensions
      ),
      tagsNames: retrivalNames(enableBudgetDetails?.Details?.filters?.tags),
    };
  }, [
    enableBudgetDetails?.Details?.filters?.dimensions,
    enableBudgetDetails?.Details?.filters?.tags,
    dimensionData,
  ]);

  const filterValues = useMemo(() => {
    const retriveValues = (data) => {
      const dimensionLookup = (dimensionData?.dimensions || []).reduce(
        (acc, cur) => {
          acc[cur.id] = cur.name;
          return acc;
        },
        {}
      );

      return (data || []).flatMap((e) =>
        (e?.values || []).map((value) => ({
          name: dimensionLookup[e.id] || e.name,
          columnName: value,
        }))
      );
    };

    const retriveTagValues = (data) => {
      return (data || []).flatMap((e) =>
        (e?.values || []).map((value) => ({
          name: e.name,
          columnName: value,
        }))
      );
    };

    return {
      dimensionsValues: retriveValues(
        enableBudgetDetails?.Details?.filters?.dimensions
      ),
      tagsValues: retriveTagValues(enableBudgetDetails?.Details?.filters?.tags),
    };
  }, [
    enableBudgetDetails?.Details?.filters?.dimensions,
    enableBudgetDetails?.Details?.filters?.tags,
    dimensionData,
  ]);

  const budgetQuery = {
    BudgetName: enableBudgetDetails?.BudgetName,
    Id: enableBudgetDetails?.Id,
    tagsKeys: (enableBudgetDetails?.Details?.filters?.tags || []).map((e) => ({
      name: e?.name,
      id: e?.name,
    })),
    filterNames,
    filterValues,
  };

  const { data: dimensionKeys, isLoading } = useDimensionTagsKeys({
    service,
    budgetQuery,
  });

  const dimensionDataValues = useMemo(() => {
    let d = [];
    if (
      (!enableBudgetDetails?.BudgetName && !enableBudgetDetails?.Id) ||
      enableBudgetDetails?.BudgetName === undefined ||
      enableBudgetDetails?.BudgetName === null ||
      enableBudgetDetails?.Id === undefined ||
      enableBudgetDetails?.Id === null
    ) {
      d = (dimensionData?.dimensions || []).map(({ id, name }) => ({
        id,
        name,
      }));
    } else {
      d = filterDimensionNames?.dimensionsAzure || [];
    }
    return d;
  }, [
    dimensionData?.dimensions,
    enableBudgetDetails?.BudgetName,
    enableBudgetDetails?.Id,
    filterDimensionNames?.dimensionsAzure,
  ]);

  const selectedDimensions = dimensionDataValues
    .filter((e) => filterNames.dimensionsAzure[e.id])
    .map((y) => y.id);

  const separatedDimensions = selectedDimensions.reduce((acc, dimension) => {
    acc[dimension] = filterValues.dimensionsValues
      .filter((e) => e.name === dimension)
      .map((e) => e.columnName);
    return acc;
  }, {});

  useEffect(() => {
    if (
      enableBudgetDetails?.BudgetName &&
      filterContext?.selectedDimension === ""
    ) {
      if (dimensionDataValues.length > 0) {
        filterContext?.setSelectedDimension(dimensionDataValues[0].id);

        const filterData = Object.keys(separatedDimensions).map((labels) => ({
          dimension: labels || "",
          isInclude: true,
          values: separatedDimensions[labels],
        }));

        filterContext?.setSelectedFilters(filterData);
      }

      if (budgetQuery?.tagsKeys?.length > 0) {
        const selectedTagsValues = budgetQuery.tagsKeys.flatMap((tagKey) => {
          const values = (filterValues?.tagsValues || [])
            .filter((item) => item?.name === tagKey.id)
            .map((item) => item?.columnName);

          return {
            filterOn: tagKey.id || "",
            filterType: "include",
            values: values || [],
          };
        });

        filterContext?.setSelectedTags(selectedTagsValues);
      }
    }
  }, [
    budgetQuery.tagsKeys,
    dimensionData?.dimensions,
    dimensionDataValues,
    enableBudgetDetails,
    enableBudgetDetails?.BudgetName,
    enableBudgetDetails.Details,
    filterContext,
    filterValues.dimensionsValues,
    filterValues?.tagsValues,
    separatedDimensions,
  ]);

  // -------------------------------------------------------------
  useEffect(() => {
    if (
      filterContext?.selectedDimension === "tags" &&
      !filterContext?.selectedGroupTags &&
      dimensionKeys?.length > 0
    ) {
      filterContext?.setSelectedGroupTags(dimensionKeys[0].id);
    }
  }, [dimensionKeys, filterContext]);

  useEffect(() => {
    let storage = localStorage?.getItem("usageDetails");
    if (storage) {
      storage = JSON.parse(storage);
      filterContext.setSelectedGroupTags(storage?.tagname);
    }
  }, []);


  const [uiSelectedDimension, setUiSelectedDimension] = useState(
    filterContext?.selectedDimension || "" 
  );
  
  const handleSelect = (selectedItem) => {
    if (selectedItem.id !== "all") { 
      setUiSelectedDimension(selectedItem.id); 
      filterContext?.setSelectedDimension(selectedItem.id);
    } else if (selectedItem.id === "all") { 
      setUiSelectedDimension("all"); 
      filterContext?.setSelectedDimension(""); 
    } else {
      
    }
  };
  const selectedDimension = uiSelectedDimension || filterContext?.selectedDimension || "";

  return (
    <>
      {isLoading && <FilterState />}
      {dimensionKeys && (
        <div className={styles.filter_main_div}>
          <div className={styles.filter_padding_div}>
            <div className={styles.dimension_div}>
              <div className={styles.dimension_name_css}>Group By</div>
              <div className={styles.service_dropdown_box}>
                <FilterSearch
                  hideSearch
                  options={[{ id: "all", name: "All" }, ...dimensionDataValues]}
                  displayValue="name"
                  selectedValues={selectedDimension ? [selectedDimension] : []} 
                  searchFieldPlaceholder={"Filter Dimensions"}
                  onSelect={handleSelect}
                  toggle={
                    <FilterSearchToggle placeholder="-- Select Dimension --" />
                  }
                />
              </div>

              {/* -------------------------------------------------------------------------- */}
              {filterContext?.selectedDimension === "tags" && (
                <div
                  className={styles.service_dropdown_box}
                  style={{ marginTop: "4px" }}
                >
                  <FilterSearch
                    options={[
                      ...(dimensionKeys || []).map((item) => ({
                        id: item.id,
                        name: item.name,
                      })),
                    ]}
                    displayValue="name"
                    selectedValues={
                      filterContext?.selectedGroupTags
                        ? [filterContext.selectedGroupTags]
                        : []
                    }
                    searchFieldPlaceholder={"Filter Tags"}
                    onSelect={(selectedItem) => {
                      filterContext?.setSelectedGroupTags(selectedItem.id);
                    }}
                    toggle={
                      <FilterSearchToggle placeholder="-- Select Tags --" />
                    }
                  />
                </div>
              )}
              {/* ------------------------------------------------------ */}
            </div>

            <div className={styles.dimension_div}>
              <div className={styles.dimension_name_css}>Granularity</div>
              <div className={styles.service_dropdown_box}>
                <FilterSearch
                  hideSearch
                  options={[
                    ...Object.entries(GRANULARITY).map(([id, name]) => ({
                      id,
                      name,
                    })),
                  ]}
                  displayValue="name"
                  selectedValues={[filterContext?.selectedGranularity]}
                  onSelect={(selectedItem) => {
                    filterContext?.setSelectedGranularity(selectedItem.id);
                  }}
                  toggle={<FilterSearchToggle placeholder="-- Select --" />}
                />
              </div>
            </div>

            <hr className="w-100 mb-0 mt-0" />

            <div className={styles.filter_box_div}>
              <div className={styles.filter_name_addsign}>
                <div className={styles.filter_name}>Filters</div>
                <Tooltip
                  tooltipContent="Please select dimension"
                  overlayProps={{
                    placement: "bottom-end",
                  }}
                >
                  {(_show, setShow) => {
                    const disableFilterBtn =
                      filterContext.selectedFilters.length >= 1 &&
                      filterContext.selectedFilters.some(
                        (e) => e.dimension.length === 0
                      );
                    return (
                      <div
                        onMouseEnter={() => {
                          if (disableFilterBtn) setShow(true);
                        }}
                        onMouseLeave={() => setShow(false)}
                      >
                        <Button
                          disabled={disableFilterBtn}
                          style={{
                            opacity: disableFilterBtn ? 0.4 : 1,
                            fill: disableFilterBtn ? "#CED4DA" : "",
                            // pointerEvents: disableFilterBtn ? "none" : "pointer",
                          }}
                          variant="link"
                          className="p-0"
                          onClick={() => {
                            filterContext.setSelectedFilters((arr) => {
                              const newArr = [...arr];
                              newArr.push({
                                dimension: "",
                                isInclude: true,
                                values: [],
                              });
                              return newArr;
                            });
                          }}
                        >
                          <img src={add} alt="" />
                        </Button>
                      </div>
                    );
                  }}
                </Tooltip>
              </div>

              {filterContext.selectedFilters.map((filter, index) => (
                <FilterItem
                  service={service}
                  filter={filter}
                  index={index}
                  enableBudgetDetails={budgetQuery}
                  dimensionData={dimensionData}
                />
              ))}
            </div>

            <hr className="w-100 mb-0 mt-0" />

            <div className={styles.filter_box_div}>
              <div className={styles.filter_name_addsign}>
                <div className={styles.filter_name}>Tags</div>

                <Tooltip
                  tooltipContent="Please select tags"
                  overlayProps={{
                    placement: "bottom-end",
                  }}
                >
                  {(_show, setShow) => {
                    const disableBtn =
                      filterContext.selectedTags.length >= 1 &&
                      dimensionKeys.length === 0 &&
                      filterContext.selectedTags.some(
                        (e) => e.filterOn.length === 0
                      );
                    return (
                      <div
                        onMouseEnter={() => {
                          if (disableBtn) setShow(true);
                        }}
                        onMouseLeave={() => setShow(false)}
                      >
                        <Button
                          disabled={disableBtn}
                          style={{
                            opacity: disableBtn ? 0.4 : "",
                            fill: disableBtn ? "#CED4DA" : "",
                            cursor: disableBtn ? "none" : "pointer",
                          }}
                          variant="link"
                          className="p-0"
                          onClick={() => {
                            filterContext.setSelectedTags((arr) => {
                              const newArr = [...arr];
                              newArr.push({
                                filterOn: "",
                                filterType: "include",
                                values: [],
                              });
                              return newArr;
                            });
                          }}
                        >
                          <img src={add} alt="" />
                        </Button>
                      </div>
                    );
                  }}
                </Tooltip>
              </div>
              <div className={styles.checkboxArea}>
                <input
                  type="checkbox"
                  style={{ width: "16px" }}
                  onChange={(e) => {
                    filterContext.setUntagged(e.currentTarget.checked);
                  }}
                />
                <span>Show Untagged Only</span>
              </div>
              {/* <div className={styles.checkboxArea}>
                <input
                  type="checkbox"
                  style={{ width: "16px" }}
                  onChange={(e) =>
                    filterContext.setShowUntaggable(e.currentTarget.checked)
                  }
                />
                <span>Show Untaggable only</span>
              </div> */}
              {filterContext.selectedTags.map((tag, index) => (
                <TagItem
                  service={service}
                  tag={tag}
                  index={index}
                  tagItems={dimensionKeys || []}
                  enableBudgetDetails={budgetQuery}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterSection;
